<template>
  <div>
    <NSpace>
      <NH4 prefix="bar" class="module-title" v-clickoutside="confirmRename">
        <span v-if="!editing">{{ moduleData.title }}</span>
        <NSpace v-else>
          <NInput
            size="small"
            maxlength="5"
            show-count
            clearable
            v-model:value="moduleData.title"
            @keyup.enter="confirmRename"
          />
          <NButton text style="margin-top: 5px;" @click="cancelRename">
            <template #icon>
              <ClearOutlined />
            </template>
          </NButton>
          <NButton text style="margin-top: 5px;" @click="confirmRename">
            <template #icon>
              <CheckOutlined />
            </template>
          </NButton>
        </NSpace>
      </NH4>
      <NButton text style="margin-top: -2px;" @click="resourcesShow = !resourcesShow">
        <template #icon v-if="resourcesShow">
          <NIcon size="30">
            <ArrowDropDownOutlined />
          </NIcon>
        </template>
        <template #icon v-else>
          <NIcon size="30">
            <ArrowLeftOutlined />
          </NIcon>
        </template>
      </NButton>
      <NButton v-show="!readOnly && !editing" size="tiny" type="primary" text style="margin-top: 7px;" @click="handleClickRename">重命名</NButton>
      <NButton v-show="!readOnly && modulesNum > 1 && !editing" size="tiny" type="error" text style="margin-top: 7px;" @click="emit('module-del-click', moduleData)">删除</NButton>
    </NSpace>
    <div class="resource-wrap" v-show="resourcesShow">
      <div v-show="resourceListShow">
        <ul
          class="resource-list"
          v-if="Array.isArray(moduleData[resourceListKey]) && moduleData[resourceListKey].length > 0"
        >
          <li
            class="resource-item" :class="{ 'resource-renaming': renamingResource === resourceItem }"
            v-for="(resourceItem, index) in moduleData[resourceListKey]"
            :key="index"
          >
            <NSpace justify="space-between">
              <NSpace v-if="renamingResource === resourceItem" v-clickoutside="confirmResourceRename">
                <NInput
                  size="small"
                  maxlength="20"
                  show-count
                  clearable
                  v-model:value="renamingResourceTitle"
                  @keyup.enter="confirmResourceRename"
                />
                <NButton text style="margin-top: 5px;" @click="handleCancelResourceRename">
                  <template #icon>
                    <ClearOutlined />
                  </template>
                </NButton>
                <NButton text style="margin-top: 5px;" @click="confirmResourceRename">
                  <template #icon>
                    <CheckOutlined />
                  </template>
                </NButton>
              </NSpace>
              <span v-else>{{ resourceItem.title }}</span>
              
              <NSpace v-if="!readOnly && renamingResource !== resourceItem">
                <NButton
                  v-if="Number(resourceItem.data_type) === resourceEnum.EXERCISES_RESOURCE_TYPE"
                  size="tiny"
                  type="primary"
                  text
                  @click="handleClickResourceRename(resourceItem)"
                >重命名</NButton>
                <NButton
                  v-if="Number(resourceItem.data_type) === resourceEnum.EXERCISES_RESOURCE_TYPE"
                  size="tiny"
                  type="warning"
                  text
                  @click="emit('exercises-edit-click', { moduleData, resourceItem })"
                >编辑</NButton>
                <NButton
                  size="tiny"
                  type="error"
                  text
                  @click="emit('resource-del-click', { moduleData, resourceItem })"
                >删除</NButton>
              </NSpace>
            </NSpace>
          </li>
        </ul>
        <div
          class="up-file-container"
          v-show="Array.isArray(moduleData.fileList) && moduleData.fileList.length > 0"
        >
          <AddLocalFile
            ref="addLocalFileRef"
            trigger-type="external"
            @file-list-change="handleUpFileChange"
            change-with-file-info 
            general-file-upload
            :init-list="uploadList"
            :auto-init-list="false"
            :read-only="readOnly"

            :maxBytesSize="300 * 1024 * 1024"
          />
        </div>
      </div>
      <span
        v-show="!resourceListShow && readOnly"
        style="padding: 0 10px; color: #ccc;"
      >（无内容）</span>
      <div v-show="!readOnly">
        <NSpace>
          <NButton type="primary" size="small" @click="emit('add-teaching-click', moduleData)">从教学库添加</NButton>
          <NDropdown
            v-if="componentType !== 'task'"
            width="trigger"
            trigger="click"
            :options="exerciseAddOptions"
            @select="handleAddExercise"
          >
            <NButton type="primary" size="small" icon-placement="right">
              <template #default>从习题库添加</template>
              <template #icon>
                <NIcon color="#fff" size="18">
                  <ChevronDown />
                </NIcon>
              </template>
            </NButton>
          </NDropdown>
          <NButton type="primary" size="small" title="文件不超过 300MB" @click="handleClickUp">上传附件</NButton>
        </NSpace>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, watch, nextTick, computed } from 'vue';
  import { useMessage } from 'naive-ui';
  import { ArrowLeftOutlined, ArrowDropDownOutlined, ClearOutlined, CheckOutlined } from '@vicons/material';
  import { ChevronDown } from '@vicons/ionicons5';
  
  import AddLocalFile from '@/components/AddLocalFile/AddLocalFile.vue';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';
  import exerciseAddTypeEnum from '@/enumerators/exercises-types-map.js';

  const props = defineProps({
    moduleData: {
      required: true,
      type: Object
    },
    modulesNum: {
      required: true,
      type: Number
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    componentType: {
      type: String,
      default: ''
    },
    resourceListKey: {
      type: String,
      default: ''
    }
  });
  const emit = defineEmits([
    'add-teaching-click',
    'add-exercises-click',
    'exercises-edit-click',
    'resource-del-click',
    'module-del-click',
    'exercises-rename',
    'file-list-change'
  ]);

  const message = useMessage();
  const resourcesShow = ref(true);
  const editing = ref(false);
  let srcTitle = '';
  const handleClickRename = () => {
    srcTitle = props.moduleData.title;
    editing.value = true;
  };
  const cancelRename = () => {
    props.moduleData.title = srcTitle;
    editing.value = false;
  };
  const confirmRename = () => {
    if (!editing.value) {
      return;
    }
    const title = props.moduleData.title.trim();
    if (!title) {
      message.error('请填写模块名称');
    } else if (title.length < 2) {
      message.error('模块名称限制2～5个字符');
    } else {
      editing.value = false;
    }
  };
  const exportedCancelRename = () => {
    if (editing.value) {
      cancelRename();
    }
  };

  const renamingResource = ref(null);
  const renamingResourceTitle = ref('');
  function confirmResourceRename() {
    if (!renamingResource.value) {
      return;
    }
    if (!renamingResourceTitle.value.trim()) {
      message.error('请输入习题名称');
      return;
    }
    emit('exercises-rename', {
      moduleData: props.moduleData,
      resourceItem: renamingResource.value,
      newTitle: renamingResourceTitle.value
    });
    renamingResource.value = null;
  }
  function handleCancelResourceRename() {
    renamingResource.value = null;
  }
  function handleClickResourceRename(resourceItem) {
    renamingResource.value = resourceItem;
    renamingResourceTitle.value = resourceItem.title;
  }

  const addLocalFileRef = ref(null);
  const uploadList = ref([]);
  function handleClickUp() {
    addLocalFileRef.value.getUploadRef().openOpenFileDialog();
  }
  function handleUpFileChange(args) {
    emit('file-list-change', {
      moduleData: props.moduleData,
      ...args
    })
  }

  const resourceListShow = computed(() => {
    const _data = props.moduleData;
    const _key = props.resourceListKey;
    return Array.isArray(_data[_key]) && _data[_key].length > 0 ||
      (Array.isArray(_data.fileList) && _data.fileList.length > 0);
  });

  watch(
    () => props.moduleData,
    ({ fileList }) => {
      nextTick(() => {
        addLocalFileRef.value.initListFn(
          fileList && fileList.length > 0 ?
            fileList :
            []
        );
      });
    },
    { immediate: true }
  );

  const exerciseAddOptions = Object.keys(exerciseAddTypeEnum).map(key => ({ label: exerciseAddTypeEnum[key], key }));
  function handleAddExercise(key) {
    emit('add-exercises-click', {
      key: Number(key),
      moduleData: props.moduleData
    });
  }
  
  defineExpose({
    exportedCancelRename
  });
</script>

<style lang="less" scoped>
  .module-title {
    line-height: 24px;
    font-size: 14px;
  }

  .resource-wrap {
    padding: 0 25px 0 15px;
    margin: -6px 0 18px;
  }

  .resource-list,
  .resource-item {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .resource-item {
    line-height: 24px;
    padding: 0 10px;
    margin-bottom: 10px;
    &:hover {
      background-color: #f5f5f5;
    }
  }

  .up-file-container {
    margin-bottom: 12px;
    border: 1px solid #eee;

    :deep(.add-local-file) {
      margin-bottom: 0 !important;
    }

    :deep(.n-upload-trigger) {
      display: none;
    }

    :deep(.n-upload-file-list) {
      margin-top: 0;
    }

    :deep(.n-upload-file-info) {
      padding: 2px 0 1px;
    }
  }
</style>