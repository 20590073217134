<template>
  <div>
    <NSpace style="margin-bottom: 10px;">
      <NButton @click="handleClickImport">导入架构</NButton>
      <NButton type="primary" @click="handleClickExport">导出架构</NButton>
    </NSpace>
    <NGrid :x-gap="15" :cols="24">
      <NGi :span="8">
        <MaterialDir
          ref="materialDirRef"
          :dir-data="dirData"
          @editing-section-change="handleEditingSectionchange"
        />
      </NGi>
      <NGi :span="16">
        <MaterialSection
          ref="materialSectionRef"
          :editing-section="editingSection"
          :chapter-section-type="chapterSectionType"
        />
      </NGi>
    </NGrid>

    <ImportDirModal
      ref="importRef"
      @import-success="handleImportSuccess"
    />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, nextTick } from 'vue';
  import { useMessage } from 'naive-ui';

  import PageLoading from '@/components/PageLoading/index.vue';
  import MaterialDir from './MaterialDir.vue';
  import MaterialSection from './MaterialSection.vue';
  import ImportDirModal from './ImportDirModal.vue';

  import { exportDir } from '@/api/material.js';
  import {
    generateChapterItem,
    getChapters
  } from './tool-fns.js';

  import { chapterSection } from '../enum/edit-type-map.js';

  const message = useMessage();
  const loading = ref(false);
  const materialDirRef = ref(null);
  const importRef = ref(null);

  const handleClickImport = () => {
    importRef.value.openModal();
  };
  const handleImportSuccess = chapters => {
    handleEditingSectionchange({});
    initData(chapters);
  };
  const handleClickExport = () => {
    loading.value = true;
    const chapters = [];
    dirData.value.forEach(({ title, section_list }) => {
      chapters.push({
        title,
        type: chapterSection.CHAPTER
      });
      if (Array.isArray(section_list)) {
        section_list.forEach(({ title }) => {
          chapters.push({
            title,
            type: chapterSection.SECTION
          });
        });
      };
    });
    exportDir({
      Material: chapters
    }).then(res => {
      saveAs(new Blob([res], {
        type: 'application/octet-stream'
      }), `章节架构.xlsx`);
    }).catch(err => {}).finally(() => {
      loading.value = false;
    });
  };

  const dirData = ref([
    /* {
      key: '1-1',
      title: '章1',
      section_list: [
        {
          key: '2-1-1',
          title: '节1-1'
        },
        {
          key: '2-1-2',
          title: '节1-2'
        },
        {
          key: '2-1-3',
          title: '节1-3'
        }
      ]
    },
    {
      key: '1-2',
      title: '章2',
      section_list: [
        {
          key: '2-2-1',
          title: '节2-1'
        },
        {
          key: '2-2-2',
          title: '节2-2'
        }
      ]
    },
    {
      key: '1-3',
      title: '章3',
      section_list: [
        {
          key: '2-3-1',
          title: '节3-1'
        }
      ]
    }, */
  ]);

  const editingSection = ref({});
  const chapterSectionType = ref('');
  const handleEditingSectionchange = params => {
    editingSection.value = params.data;
    chapterSectionType.value = params.chapterSectionType;
  };

  const initData = (chapters) => {
    try {
      if (Array.isArray(chapters)) {
        dirData.value = chapters.map((chapterItem, chapterIndex) => generateChapterItem(chapterItem, chapterIndex));
      } else {
        dirData.value = [
          generateChapterItem()
        ];
      }
    } catch (e) {
      console.log(e);
    }
    nextTick(() => {
      materialDirRef.value.updateDirDataItemTags();
      materialDirRef.value.expandAllChapters();
    });
  };

  const materialSectionRef = ref(null);

  defineExpose({
    initData,
    getChapters: () => { return getChapters(dirData.value); },
    getRunningChapters: () => {return dirData.value; },
    cancelAllRename: () => {
      materialDirRef.value.cancelItem();
      materialSectionRef.value.cancelAllModuleNameRename();
    }
  });
</script>