<template>
  <NModal
    v-model:show="modalShow"
    preset="card"
    size="small"
    title="教学库"
    :segmented="{ content: 'hard', footer: 'hard' }"
    style="width: 1200px;"
    :mask-closable="false"
    @after-leave="clearModal"
  >
    <template #default>
      <div>
        <TeachingListWithSearch
          ref="teachingListRef"

          label-content-height="calc(100vh - 240px)"
          table-content-height="calc(100vh - 350px)"
        />
      </div>
    </template>
    <template #footer>
      <NSpace justify="end">
        <NButton @click="closeModal">取消</NButton>
        <NButton type="primary" @click="confirmModal">确定</NButton>
      </NSpace>
    </template>
  </NModal>
</template>

<script setup>
  import { ref, nextTick } from 'vue';

  import TeachingListWithSearch from '@/components/TeachingListWithSearch/TeachingListWithSearch.vue';

  const emit = defineEmits(['teaching-add']);

  const modalShow = ref(false);
  const openModal = (args) => {
    modalShow.value = true;
    nextTick(() => {
      teachingListRef.value.initData(args);
    });
  };
  const clearModal = () => {};
  const closeModal = () => {
    modalShow.value = false;
  };
  const confirmModal = () => {
    emit('teaching-add', teachingListRef.value.getSelectedTeachings());
    closeModal();
  };

  const teachingListRef = ref(null);
  
  defineExpose({
    openModal
  });
</script>