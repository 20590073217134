<template>
  <div style="border: 1px solid #eee;">
    <NCard
      v-show="!!editingSection.key"
      size="small"
      :bordered="false"
      title="编辑章节内容"
      :segmented="{ content: 'hard' }"
    >
      <div v-if="Array.isArray(editingSection[moduleListKey])">
        <SectionModule
          v-for="(moduleItem, index) in editingSection[moduleListKey]" :key="index"
          :ref="setSectionModuleRef"
          :read-only="editingSection.readOnly"
          :module-data="moduleItem"
          :modules-num="editingSection[moduleListKey].length"
          @add-teaching-click="handleClickAddTeaching"
          @add-exercises-click="handleClickAddExercises"
          @exercises-edit-click="handleClickEditExercises"
          @resource-del-click="handleClickDelResource"
          @module-del-click="handleClickDelModule"
          @exercises-rename="handleResourceRename"
          @file-list-change="handleFileListChange"
          :resource-list-key="resourceListKey"
          v-bind="$attrs"
        />
        <NButton block @click="addModule" v-show="!editingSection.readOnly">
          <template #icon>
            <NIcon>
              <PlusOutlined />
            </NIcon>
          </template>
          添加模块
        </NButton>
      </div>
    </NCard>
    <div v-show="!editingSection.key" style="padding: 12px 16px; color: #ccc;">
      <span>请选择章/节进行编辑</span>
    </div>

    <AddExercisesModal
      ref="addExercisesModalRef"
      @exercise-id-add="handleAddExerciseIds"
    />
    <AddTeachingsModal
      ref="addTeachingsModalRef"
      @teaching-add="handleAddTeachings"
    />
  </div>
</template>

<script setup>
  import { ref, onBeforeUpdate, computed } from 'vue';
  import { PlusOutlined } from '@vicons/material';

  import SectionModule from './SectionModule.vue';
  import AddExercisesModal from '@/components/AddExercisesModal/AddExercisesModal.vue';
  import AddTeachingsModal from '@/components/AddTeachingsModal/AddTeachingsModal.vue';

  import {
    generateModuleItem,
    generateResourceItem
  } from './tool-fns.js';

  import { resourceEnum } from '@/enumerators/resource-types-map.js';

  const props = defineProps({
    editingSection: {
      type: Object,
      default: () => {}
    },
    chapterSectionType: {
      type: String,
      default: ''
    }
  });

  const moduleListKey = computed(() => {
    switch (props.chapterSectionType) {
      case 'chapter':
        return 'material_chapter_type_list';
      case 'section':
        return 'material_section_type_list';
    }
  });
  const resourceListKey = computed(() => {
    switch (props.chapterSectionType) {
      case 'chapter':
        return 'chapter_type_source_list';
      case 'section':
        return 'type_source_list';
    }
  });

  const addExercisesModalRef = ref(null);
  const addTeachingsModalRef = ref(null);

  let addingResourceModule;
  const handleClickAddTeaching = moduleData => {
    addingResourceModule = moduleData;
    const list = addingResourceModule[resourceListKey.value];
    const filterIds = Array.isArray(list) ?
      list.filter(({ data_type }) => Number(data_type) === resourceEnum.TEACHING_RESOURCE_TYPE).map(({ source_id }) => source_id[0]) :
      [];
    addTeachingsModalRef.value.openModal({
      filterIds
    });
  };
  const handleClickAddExercises = ({ key, moduleData }) => {
    addingResourceModule = moduleData;
    editingResourceIndex = -1;
    addExercisesModalRef.value.changeIsHomework(0);
    addExercisesModalRef.value.openModal({ key });
  };

  const handleAddExerciseIds = ({ ids, title, typeKey }) => {
    const list = addingResourceModule[resourceListKey.value];
    if (editingResourceIndex === -1) { // 新增习题集
      if (Array.isArray(ids) && ids.length > 0) {
        // const seqStr = list.filter(({ data_type }) => Number(data_type) === resourceEnum.EXERCISES_RESOURCE_TYPE).length + 1;
        // const exerciseSetTitle = `习题${seqStr}`;
        const exerciseSetTitle = title;
        list.push(
          generateResourceItem(
            {
              data_type: String(resourceEnum.EXERCISES_RESOURCE_TYPE),
              source_id: ids.join(','),
              exerciseSetTitle,
              typeStatus: typeKey
            },
            exerciseSetTitle
          )
        );
      }
    } else { // 编辑习题集
      if (Array.isArray(ids) && ids.length > 0) {
        // 替换
        const exerciseSetTitle = list[editingResourceIndex].title;
        const typeStatus = list[editingResourceIndex].typeStatus;
        list.splice(
          editingResourceIndex,
          1,
          generateResourceItem(
            {
              data_type: String(resourceEnum.EXERCISES_RESOURCE_TYPE),
              source_id: ids.join(','),
              exerciseSetTitle,
              typeStatus
            },
            exerciseSetTitle
          )
        );
      } else {
        // 删除
        list.splice(editingResourceIndex, 1);
        // updateExerciseSetsTitle();
      }
    }

    console.log(editingResourceIndex);
  };
  const handleAddTeachings = list => {
    if (Array.isArray(list) && list.length > 0) {
      const dstList = addingResourceModule[resourceListKey.value];
      list.forEach(({ id, title }) => {
        dstList.push(
          generateResourceItem(
            {
              data_type: String(resourceEnum.TEACHING_RESOURCE_TYPE),
              source_id: id,
              title
            },
            title
          )
        );
      });
    }
  };
  function handleFileListChange({ moduleData, fileList }) {
    moduleData.fileList = fileList;
  }

  let editingResourceIndex = -1;
  const updateExerciseSetsTitle = () => {
    const list = addingResourceModule[resourceListKey.value];
    let exerciseResourceSeq = 1;
    list.forEach(item => {
      switch (Number(item.data_type)) {
        case resourceEnum.EXERCISES_RESOURCE_TYPE:
          item.title = `习题${exerciseResourceSeq++}`;
          break;
      }
    });
  };
  const handleClickEditExercises = ({ moduleData, resourceItem }) => {
    addingResourceModule = moduleData;
    editingResourceIndex = moduleData[resourceListKey.value].indexOf(resourceItem);

    addExercisesModalRef.value.changeIsHomework(0);
    addExercisesModalRef.value.openModal({
      initIds: resourceItem.source_id,
      key: resourceItem.typeStatus
    });
  };
  const handleClickDelResource = ({ moduleData, resourceItem }) => {
    const wrap = moduleData[resourceListKey.value];
    wrap.splice(wrap.indexOf(resourceItem), 1);

    /*
    if (Number(resourceItem.data_type) === resourceEnum.EXERCISES_RESOURCE_TYPE) {
      updateExerciseSetsTitle();
    }
    */
  };
  const handleClickDelModule = moduleData => {
    const list = props.editingSection[moduleListKey.value];
    const index = list.indexOf(moduleData);
    list.splice(index, 1);
  };
  function handleResourceRename({ resourceItem, newTitle }) {
    resourceItem.title = newTitle;
  }

  const sectionModuleRefs = [];
  const clearSectionModuleRefs = () => {
    sectionModuleRefs.splice(0);
  };
  const setSectionModuleRef = el => {
    if (el) {
      sectionModuleRefs.push(el);
    }
  };
  onBeforeUpdate(() => {
    clearSectionModuleRefs();
  });

  const addModule = () => {
    clearSectionModuleRefs();

    props.editingSection[moduleListKey.value].push(
      generateModuleItem({}, props.chapterSectionType)
    );
  };

  defineExpose({
    cancelAllModuleNameRename: () => {
      sectionModuleRefs.forEach(moduleRef => moduleRef.exportedCancelRename());
    }
  });
</script>