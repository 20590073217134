<template>
  <NModal
    v-model:show="modalShow"
    preset="card"
    size="small"
    :title="`习题库${typeTitle}`"
    :segmented="{ content: 'hard', footer: 'hard' }"
    style="width: 1200px;"
    :mask-closable="false"
    @after-leave="clearModal"
  >
    <template #default>
      <div>
        <ExerciseListWithSearch
          ref="exerciseListRef"
          :exercise-homework="isHomework"
          :type-key="typeKey"
          label-content-height="calc(100vh - 300px)"
          table-content-height="calc(100vh - 350px)"
        />
      </div>
    </template>
    <template #footer>
      <NSpace justify="end">
        <NButton @click="closeModal">取消</NButton>
        <NButton type="primary" @click="confirmModal">确定</NButton>
      </NSpace>
    </template>
  </NModal>
</template>

<script setup>
  import { ref, nextTick } from 'vue';

  import ExerciseListWithSearch from '../ExerciseListWithSearch/ExerciseListWithSearch.vue';

  import { resourceEnum as exerciseAddTypeEnumVal } from '@/enumerators/exercises-types-map.js';

  const emit = defineEmits(['exercise-id-add']);

  const modalShow = ref(false);
  const typeTitle = ref('');

  const isHomework = ref(1)  // isHomework 0:[应试题目,实训作业] 1:[应试题目] 2:[实训作业]
  const typeKey = ref('');
  
  const openModal = args => {
    if(args != undefined){
      typeKey.value = args.key;
    }else{
      typeKey.value = args;
    }
    switch (typeKey.value) {
      case exerciseAddTypeEnumVal.TEACHING_RESOURCE_TYPE:
        typeTitle.value = ' - 应试题目';
        break;
      case undefined:
        typeTitle.value = ' - 应试题目';
        break;
      case exerciseAddTypeEnumVal.EXERCISES_RESOURCE_TYPE:
        typeTitle.value = ' - 实训作业';
        break;
    }
    modalShow.value = true;
    nextTick(() => {
      exerciseListRef.value.initData(args);
    });
  };

  const props = defineProps({
    isTestPaperCall: {
      type: Boolean,
      default: false
    }
  });
  // console.log('是否试卷调用',props.isTestPaperCall);
  
  const clearModal = () => {};
  const closeModal = () => {
    modalShow.value = false;
  };
  const confirmModal = () => {
    // 是否试卷调用
    if(props.isTestPaperCall){
      emit('exercise-id-add', exerciseListRef.value.getSelectedExerciseIds());
    }else{
      emit('exercise-id-add', {
        ids: exerciseListRef.value.getSelectedExerciseIds(),
        title: typeTitle.value.replace(' - ', ''),
        typeKey: typeKey.value
      });
    }
    closeModal();
  };

  const exerciseListRef = ref(null);
  
  defineExpose({
    openModal,
    confirmModal,
    changeIsHomework(val) {
      isHomework.value = val;
    }
  });
</script>